// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Noty
@import '~vuejs-noty/dist/vuejs-noty.css';

// Vue icon
.v-icon,
.custom-icon {
    width: 24px;
}

.button-icon {
    width: 16px;
}

.md-icon {
    width: 20px !important;
    height: 20px !important;
}

// RICH EDITOR
.rich-text-editor {
    margin: 4px 0 24px;
}

.rich-text-editor label {
    color: #AAAAAA;
}

.ck-source-editing-area {
    min-height: 300px;
}

.rich-text-editor .md-error {
    color: var(--error-color);
    padding: 12px 0px 0px 0px;
    height: 20px;
    font-weight: 300;
    font-size: 12px;
}

.md-same-height-400 {
    height: 400px;
}

.stats-bg-percent {
    position: absolute;
    top: 0.5em;
    right: 1em;
    background-color: #dcf0dc;
    height: 2.5em;
    display: flex;
    justify-content: right;
    align-items: center;
    width: 1%;
    text-align: right;
    padding-right: 10px;
    overflow: hidden;
}

.stats-bg-percent div {
    position: absolute;
    right: 0.5em;
}

.label-for-filter {
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.label-for-filter>span {
    font-weight: bold;
    display: inline-block;
    min-width: 10em;
}
